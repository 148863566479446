import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './style.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import {
	CheckboxField,
	DatePickerField,
	InputField,
	SelectField,
	TextAreaField,
} from '@shared/ui/form-items';
import { Button, Typography } from 'antd';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { EditCvModel } from '@features/edit-cv';
import { AppModel } from '@processes/app';
const { Text } = Typography;

export const text = {
	en: 'This field is required',
	ru: 'Поле обязательно к заполнению',
};
type Form = {
	modal?: never;
	closeModal?: never;
	onSetValue: Function;
	defaultValue?: never;
};
type FormInModal = {
	modal: boolean;
	closeModal: () => void;
	onSetValue: (value: EditCvModel.TPastProjectExpForm) => void;
	defaultValue: EditCvModel.TPastProjectExpForm;
};
type Props = Form | FormInModal;

const translation = {
	name: {
		ru: 'Краткое описание проекта',
		en: 'Project summary',
	},
	placeholder_name: {
		ru: 'Введите краткое описание проекта',
		en: 'Enter project summary',
	},
	period_start: {
		ru: 'Начало проекта',
		en: 'Project period (start)',
		later: {
			en: 'The start date cannot be later than the current month',
			ru: 'Дата начала не может быть позже чем текущий месяц',
		},
		beforePeriodEnd: {
			en: 'The start date cannot be later than the end date.',
			ru: 'Дата начала не может быть позже даты окончания.',
		},
	},
	placeholder_period_start: {
		ru: 'Выберите дату',
		en: 'Enter period',
	},
	period_end: {
		ru: 'Конец проекта',
		en: 'Project period (end)',
		later: {
			en: 'The end date cannot be later than the current month',
			ru: 'Дата окончания не может быть позже чем текущий месяц',
		},
		beforePeriodStart: {
			en: 'The end date cannot be before than the end date.',
			ru: 'Дата окончания не может быть раньше даты окончания.',
		},
	},
	placeholder_period_end: {
		ru: 'Выберите дату',
		en: 'Enter period',
	},
	description: {
		ru: 'Описание',
		en: 'Description',
	},
	placeholder_description: {
		ru: 'Расскажите о проекте',
		en: 'Tell about project',
	},
	team: {
		ru: 'Команда',
		en: 'Team',
	},
	placeholder_team: {
		ru: 'Перечислите названия должностей',
		en: 'List the titles of the positions',
	},
	position: {
		ru: 'Должность в компании',
		en: 'Job position',
	},
	placeholder_position: {
		ru: 'Должность в компании (во время проекта)',
		en: 'Position in the company (during the project)',
	},
	role: {
		ru: 'Роль на проекте',
		en: 'Project roles',
	},
	placeholder_role: {
		ru: 'Введите свою роль на проекте',
		en: 'Enter your position on the project',
	},
	tasks: {
		ru: 'Задачи',
		en: 'Tasks',
	},
	placeholder_tasks: {
		ru: 'Список задач на проекте',
		en: 'List your project tasks * Task * Task * Task',
	},
	technologies: {
		ru: 'Технологии',
		en: 'Technologies',
	},
	placeholder_technologies: {
		ru: 'Список технологий, используемых на проекте',
		en: 'List the technologies used in the project',
	},
	hint: {
		ru: 'Все поля должны быть заполнены',
		en: 'All fields must be completed',
	},
	add: {
		ru: 'Добавить',
		en: 'Add',
	},
	save: {
		ru: 'Изменить',
		en: 'Ok',
	},
	cancel: {
		ru: 'Закрыть',
		en: 'Cancel',
	},
};

const ValidateInputs = (required: string, locale: string) => {
	return {
		required: required,
		validate: (value: any) => {
			if (value.trim() === '')
				return locale === 'en'
					? 'You cannot insert only Spaces'
					: 'Нельзя вставлять только пробелы';
			else if (value.charAt(0) === ' ') {
				return locale === 'en'
					? "You can't start typing with a Space"
					: 'Нельзя начинать слово с пробела';
			}
		},
	};
};

const FormComponent: FC<Props> = ({ modal, closeModal, onSetValue, defaultValue }) => {
	const { locale, language } = AppModel.useAppStore((state) => ({
		locale: state.locale,
		language: state.language,
	}));
	const [dateOnGoing, setDateOnGoing] = useState(defaultValue?.dateOnGoing || false);
	const positionList = useMemo(() => {
		return language === 'ru'
			? EditCvModel.PositionsRu.map((el) => ({ label: el, value: el }))
			: EditCvModel.Positions.map((el) => ({ label: el, value: el }));
	}, [language]);
	const methods = useForm<EditCvModel.TPastProjectExpForm>({
		mode: 'all',
		defaultValues: defaultValue || {
			name: '',
			periodStart: null,
			periodEnd: null,
			dateOnGoing: false,
			description: '',
			tasks: '',
			team: '',
			technologies: '',
			jobPosition: '',
			projectRoles: '',
		},
	});
	const {
		formState: { isValid, touchedFields },
		control,
		getValues,
		reset,
		handleSubmit,
		setValue,
		trigger,
		clearErrors,
	} = methods;

	useEffect(() => {
		if (dateOnGoing) {
			setValue('periodEnd', null);
			trigger('periodStart');
			clearErrors('periodEnd');
		}
	}, [dateOnGoing]);//eslint-disable-line

	const onButtonClick = () => {
		if (isValid) {
			onSetValue(getValues());
			reset();
			setDateOnGoing(false);
		}
	};
	const onCheckboxClick = (event: CheckboxChangeEvent) => {
		setDateOnGoing(event.target.checked);
	};

	const isDisabled = !!Object.keys(touchedFields).length && !isValid;
	return (
		<div className={classNames(styles.form, { modal: modal })}>
			<FormProvider {...methods}>
				<TextAreaField
					main={{
						label: translation.name[locale],
						placeholder: translation.placeholder_name[locale],
						autoSize: { minRows: 2, maxRows: 4 },
						allowClear: true,
					}}
					name={'name'}
					control={control}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<div className={styles.date}>
					<DatePickerField
						main={{
							label: translation.period_start[locale],
							placeholder: translation.placeholder_period_start[locale],
							picker: 'month',
							format: 'MM.YYYY',
						}}
						control={control}
						name={'periodStart'}
						setValue={setValue}
						trigger={trigger}
						rules={{
							required: text[locale],
							validate: (value) => {
								if (value?.isAfter(new Date())) {
									return translation.period_start.later[locale];
								}
								if (dateOnGoing) return true;
								else {
									const periodEnd = getValues().periodEnd;
									if (!periodEnd) return true;
									else if (dayjs(value).isAfter(dayjs(periodEnd))) {
										return translation.period_start.beforePeriodEnd[locale];
									} else {
										clearErrors('periodEnd');
										return true;
									}
								}
							},
						}}
					/>
					<DatePickerField
						main={{
							label: translation.period_end[locale],
							placeholder: translation.placeholder_period_end[locale],
							picker: 'month',
							format: 'MM.YYYY',
							disabled: dateOnGoing,
						}}
						control={control}
						name={'periodEnd'}
						setValue={setValue}
						trigger={trigger}
						rules={{
							validate: (value: Dayjs | null) => {
								if (value && value?.isAfter(new Date())) {
									return translation.period_end.later[locale];
								}
								if (dateOnGoing) return true;
								else if (value === null) return text[locale];
								else {
									const periodStart = getValues().periodStart;
									if (!periodStart) return true;
									else {
										if (dayjs(value).isBefore(dayjs(periodStart))) {
											return translation.period_end.beforePeriodStart[locale];
										} else {
											clearErrors('periodStart');
											return true;
										}
									}
								}
							},
						}}
					/>
					<CheckboxField
						main={{ label: locale === 'en' ? 'Ongoing' : 'Текущий' }}
						name={'dateOnGoing'}
						control={control}
						handler={onCheckboxClick}
					/>
				</div>
				<TextAreaField
					main={{
						label: translation.description[locale],
						placeholder: translation.placeholder_description[locale],
						autoSize: { minRows: 4, maxRows: 8 },
						allowClear: true,
					}}
					name={'description'}
					control={control}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<InputField
					main={{
						label: translation.team[locale],
						placeholder: translation.placeholder_team[locale],
					}}
					name={'team'}
					control={control}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<SelectField
					main={{
						placeholder: translation.placeholder_position[locale],
						label: translation.position[locale],
						options: positionList,
						allowClear: true,
						showSearch: true,
					}}
					name={'jobPosition'}
					control={control}
					setValue={setValue}
					trigger={trigger}
					rules={{
						required: text[locale],
					}}
				/>
				<InputField
					main={{
						label: translation.role[locale],
						placeholder: translation.placeholder_role[locale],
					}}
					name={'projectRoles'}
					control={control}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<TextAreaField
					main={{
						label: translation.tasks[locale],
						placeholder: translation.placeholder_tasks[locale],
						autoSize: { minRows: 4, maxRows: 8 },
						allowClear: true,
					}}
					name={'tasks'}
					control={control}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<TextAreaField
					main={{
						label: translation.technologies[locale],
						placeholder: translation.placeholder_technologies[locale],
						autoSize: { minRows: 4, maxRows: 8 },
						allowClear: true,
					}}
					name={'technologies'}
					control={control}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<div className={styles.footer}>
					<Text>{translation.hint[locale]}</Text>
					<Button
						title={isDisabled ? translation.hint[locale] : ''}
						disabled={isDisabled}
						type={'primary'}
						onClick={handleSubmit(onButtonClick)}
						className={classNames(styles.submit)}
					>
						{modal ? translation.save[locale] : translation.add[locale]}
					</Button>
					{modal && (
						<Button onClick={closeModal} type="default">
							{translation.cancel[locale]}
						</Button>
					)}
				</div>
			</FormProvider>
		</div>
	);
};

export default FormComponent;
