import React, { useMemo, useRef, useState } from 'react';
import styles from './style.module.scss';
import GroupLabel from '@shared/ui/group-label';
import { InputField, SelectField, TextAreaField } from '@shared/ui/form-items';
import { useFormContext } from 'react-hook-form';
import { Col, Collapse, Row } from 'antd';
import InnerHTML from '@shared/lib/inner-html';
import { fillingInstructionsEn, fillingInstructionsRu, text } from './text';
import InstructionsWrapper from '../instructions-wrapper';
import { EditCvModel } from '@features/edit-cv';
import { AppModel } from '@processes/app';

const translation = {
	en: {
		introduction: 'Introduction',
		basic_info: 'Basic info',
		text_introduction: 'Text of introduction',
		placeholder_introduction: 'Tell about yourself',
		firstName: 'First name',
		placeholder_firstName: 'Enter first name',
		lastName: 'Last name',
		placeholder_lastName: 'Enter last name',
		position: 'Position',
		placeholder_position: 'Choose position',
		city: 'City(Office)',
		citizenship: 'Citizenship',
		techStack: 'Technical stack',
	},
	ru: {
		introduction: 'Введение',
		basic_info: 'Основная информация',
		text_introduction: 'Текст введения',
		placeholder_introduction: 'Расскажите о себе',
		firstName: 'Имя',
		placeholder_firstName: 'Введите имя',
		lastName: 'Фамилия',
		placeholder_lastName: 'Введите фамилию',
		position: 'Должность',
		placeholder_position: 'Выберите роль',
		city: 'Город(Офис)',
		citizenship: 'Гражданство',
		techStack: 'Технический стек',
	},
};

const About = () => {
	const { locale, language } = AppModel.useAppStore((state) => ({
		guest: state.guest,
		locale: state.locale,
		language: state.language,
	}));
	const positionList = useMemo(() => {
		return language === 'ru'
			? EditCvModel.PositionsRu.map((el) => ({ label: el, value: el }))
			: EditCvModel.Positions.map((el) => ({ label: el, value: el }));
	}, [language]);

	const citiesList = useMemo(() => {
		return language === 'ru'
			? EditCvModel.CitiesRu.map((el) => ({ label: el, value: el }))
			: EditCvModel.Cities.map((el) => ({ label: el, value: el }));
	}, [language]);

	const citizenshipsList = useMemo(() => {
		return language === 'ru'
			? EditCvModel.CitizenshipRu.map((el) => ({ label: el, value: el }))
			: EditCvModel.Citizenship.map((el) => ({ label: el, value: el }));
	}, [language]);

	const fillingInstructions = locale === 'en' ? fillingInstructionsEn : fillingInstructionsRu;
	const { control, setValue, trigger } = useFormContext<EditCvModel.CvFormDto>();
	const [activeKeys, setActiveKeys] = useState<string[]>([]);

	const instructionRef = useRef<HTMLInputElement>(null);

	const activateCollapsePanel = (activeKey: string) => {
		if (!activeKeys.includes(activeKey)) {
			setActiveKeys((prevState) => [...prevState, activeKey]);
		}
		instructionRef.current?.scrollIntoView({ behavior: 'smooth' });
	};
	return (
		<div className={styles.about}>
			<div className={styles.form}>
				<div className={styles.left}>
					<GroupLabel
						className={styles.introduction}
						handleClick={() => activateCollapsePanel(fillingInstructions[0].name)}
						text={translation[locale].introduction}
					/>
					<TextAreaField
						name={'introduction'}
						main={{
							label: translation[locale].introduction,
							placeholder: translation[locale].placeholder_introduction,
							autoSize: { minRows: 12, maxRows: 12 },
						}}
						control={control}
						rules={{
							required: text[locale],
						}}
					/>
					<div className={styles.margin_top}>
						<div className={styles.left}>
							<TextAreaField
								name={'techStack'}
								main={{
									label: translation[locale].techStack,
									placeholder: translation[locale].techStack,
									autoSize: { minRows: 12, maxRows: 12 },
								}}
								control={control}
								rules={{
									required: text[locale],
								}}
							/>
						</div>
					</div>
				</div>

				<div className={styles.right}>
					<GroupLabel
						className={styles.introduction}
						handleClick={() => activateCollapsePanel(fillingInstructions[1].name)}
						text={translation[locale].basic_info}
					/>

					<Row gutter={20} className={styles.group}>
						<Col md={12} span={24}>
							<InputField
								name={'firstName'}
								main={{
									placeholder: translation[locale].placeholder_firstName,
									label: translation[locale].firstName,
								}}
								control={control}
								rules={{
									required: text[locale],
								}}
							/>
						</Col>
						<Col md={12} span={24}>
							<InputField
								name={'lastName'}
								main={{
									placeholder: translation[locale].placeholder_lastName,
									label: translation[locale].lastName,
								}}
								control={control}
								rules={{
									required: text[locale],
								}}
							/>
						</Col>
					</Row>

					<SelectField
						main={{
							placeholder: translation[locale].placeholder_position,
							label: translation[locale].position,
							options: positionList,
							allowClear: true,
							showSearch: true,
						}}
						name={'position'}
						control={control}
						setValue={setValue}
						trigger={trigger}
						rules={{
							required: text[locale],
						}}
					/>
					<Row gutter={20} className={styles.group}>
						<Col md={12} span={24}>
							<SelectField
								main={{
									placeholder: translation[locale].city,
									label: translation[locale].city,
									options: citiesList,
									allowClear: true,
									showSearch: true,
								}}
								name={'city'}
								control={control}
								setValue={setValue}
								trigger={trigger}
								rules={{
									required: text[locale],
								}}
							/>
						</Col>
						<Col md={12} span={24}>
							<SelectField
								main={{
									placeholder: translation[locale].citizenship,
									label: translation[locale].citizenship,
									options: citizenshipsList,
									allowClear: true,
									showSearch: true,
								}}
								name={'citizenship'}
								control={control}
								setValue={setValue}
								trigger={trigger}
								rules={{
									required: text[locale],
								}}
							/>
						</Col>
					</Row>
				</div>
			</div>
			<div ref={instructionRef}>
				<InstructionsWrapper>
					<Collapse
						className="filling-instructions"
						activeKey={activeKeys}
						onChange={(key) => setActiveKeys(key as string[])}
						ghost
						items={[
							{
								key: fillingInstructions[0].name,
								label: fillingInstructions[0].name,
								children: <p dangerouslySetInnerHTML={InnerHTML(fillingInstructions[0].text)} />,
							},
						]}
					></Collapse>
				</InstructionsWrapper>
			</div>
		</div>
	);
};

export default About;
